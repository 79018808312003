// Modules
import { Suspense, lazy } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
// Styles
import "./App.css";
import SinglePage from "../components/pages/SinglePage/SinglePage";
// Components
const Landing = lazy(() => import("../components/pages/Landing/Landing"));
const Loading = lazy(() => import("../components/common/Loading/Loading"));

function App() {
  return (
    <BrowserRouter>
      <Suspense fallback={<Loading />}>
        <Switch>
          <Route exact path="/">
            <Landing />
          </Route>
          <Route exact path="/cart">
            <Landing />
          </Route>
          <Route exact path="/single/:id">
            <SinglePage />
          </Route>
          <Route path="*">
            <Loading notFound={true} />
          </Route>
        </Switch>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
