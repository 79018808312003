// Modules
import { createContext, useState } from "react";
import toast from "react-hot-toast";

const CardContext = createContext();

const CardProvider = ({ children }) => {
  const [itemsCount, setItemsCount] = useState(0);
  const [items, setItems] = useState([]);

  const addItem = (item) => {
    const copyCart = [...items];
    const itemIsExist = copyCart.findIndex((i) => i._id === item._id);
    if (itemIsExist !== -1) {
      toast.error("این مورد قبلا انتخاب شده");
      return null;
    } else {
      setItemsCount((prev) => prev + 1);
      setItems((prev) => [...prev, item]);
      return "ok";
    }
  };

  const deleteItem = (id) => {
    setItemsCount((prev) => prev - 1);
    const copyItems = [...items];
    const filteredItems = copyItems.filter((i) => i._id !== id);
    setItems(filteredItems);
  };

  return (
    <CardContext.Provider
      value={{
        itemsCount,
        items,
        addItem,
        deleteItem,
      }}
    >
      {children}
    </CardContext.Provider>
  );
};

export { CardContext, CardProvider };
