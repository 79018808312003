import i1 from "../Assets/Images/portaghal.jpg";
import i2 from "../Assets/Images/burger.jpg";
import i3 from "../Assets/Images/8140d442c2758a76e06a9e1f839df89f.jpg";
import i4 from "../Assets/Images/b803e5f1f3db7323d3e8749286a8797e.jpg";
import i5 from "../Assets/Images/d5c2106f6e6a9a8ff6a462a71371f58a.jpg";
import i6 from "../Assets/Images/cherry.jpg";
import i7 from "../Assets/Images/cola.jpg";
import i8 from "../Assets/Images/hendone.jpg";
import i9 from "../Assets/Images/limo.jpg";
import i10 from "../Assets/Images/sibzamini.jpg";
import i12 from "../Assets/Images/pizza.jpg";
import i13 from "../Assets/Images/chicken.jpg";
import i14 from "../Assets/Images/blacktea.jpg";
import i15 from "../Assets/Images/greentea.jpg";
import i16 from "../Assets/Images/bluetea.jpg";
import i17 from "../Assets/Images/chocolatecake.jpg";
import i18 from "../Assets/Images/vanilacake.jpg";
import i19 from "../Assets/Images/jamcake.jpg";
import i20 from "../Assets/Images/fruitcake.jpg";
import i21 from "../Assets/Images/caramelcake.jpg";
import i22 from "../Assets/Images/tiramisocake.jpg";
import i23 from "../Assets/Images/sezarsalad.jpg";
import i24 from "../Assets/Images/saladmorgh.jpg";
import i25 from "../Assets/Images/gharchsokhary.jpg";
import i26 from "../Assets/Images/pasta.jpg";

const products = [
  {
    name: "اسپرسو",
    category: "نوشیدنی گرم",
    categoryKey: "warm_drink",
    sizes: ["s", "m", "l"],
    rate: 4.5,
    image: i4,
    price: 35,
    _id: 0,
    description:
      "اسپرسو یک نوشیدنی گرم و محبوب است که از دانه‌های قهوه تازه آسیاب شده تهیه می‌شود. با طعم و عطر خاص خود، اسپرسو یک لحظه لذت‌بخش برای دوستداران قهوه است. این نوشیدنی تلخ و معطر با سایزهای مختلف، از ساده ترین تا غنی‌ترین طعم‌ها را ارائه می‌دهد.",
    time: 3,
  },
  {
    name: "هات چاکلت",
    category: "نوشیدنی گرم",
    categoryKey: "warm_drink",
    sizes: ["s", "m", "l"],
    rate: 5,
    image: i5,
    price: 38,
    _id: 1,
    description:
      "هات چاکلت یک نوشیدنی گرم و دلنشین است که از شکلات بسیار خوشمزه تهیه می‌شود. با طعم شیرین و شکلاتی، هات چاکلت یک گزینه عالی برای لذت بردن در روزهای سرد است. این نوشیدنی با سایزهای مختلف و با تزیینات مختلف ارائه می‌شود.",
    time: 5,
  },
  {
    name: "وایت چاکلت",
    category: "نوشیدنی گرم",
    categoryKey: "warm_drink",
    sizes: ["s", "m", "l"],
    rate: 3,
    image: i3,
    price: 46,
    _id: 2,
    description:
      "وایت چاکلت یک نوشیدنی گرم با طعم شکلات سفید است که با شیر یا شکلات سفید تهیه می‌شود. این نوشیدنی با طعم شیرین و خاص خود، گزینه ای متفاوت و لذت بخش برای دوستداران شکلات است.",
    time: 4,
  },
  {
    name: "آب هندوانه",
    category: "نوشیدنی سرد",
    categoryKey: "cold_drink",
    sizes: ["s", "m", "l"],
    rate: 4,
    image: i8,
    price: 25,
    _id: 3,
    description:
      "آب هندوانه یک نوشیدنی سرد و خنک کننده است که از تازه‌ترین هندوانه‌ها تهیه می‌شود. با طعم شیرین و ملس هندوانه، این نوشیدنی مناسب فصل‌های گرم سال و یک گزینه سالم و خوشمزه است.",
    time: 2,
  },
  {
    name: "نوشابه",
    category: "نوشیدنی سرد",
    categoryKey: "cold_drink",
    sizes: ["s", "m"],
    rate: 3.5,
    image: i7,
    price: 18,
    _id: 4,
    description:
      "نوشابه یک نوشیدنی سرد حاوی گاز است که با ترکیب مخلوطی از مواد مختلف تهیه می‌شود. با طعم متنوع و خنک کننده، نوشابه یک گزینه مناسب برای لحظاتی خنک و سرگرم‌کننده است.",
    time: 1,
  },
  {
    name: "لیموناد",
    category: "نوشیدنی سرد",
    categoryKey: "cold_drink",
    sizes: ["s"],
    rate: 4.2,
    image: i9,
    price: 22,
    _id: 5,
    description:
      "لیموناد یک نوشیدنی سرد و ملس است که از آب لیمو تازه و شکر تهیه می‌شود. با طعم تازه و ترش لیمو، این نوشیدنی یک گزینه مناسب برای روزهای گرم است.",
    time: 2,
  },
  {
    name: "پیتزا",
    category: "فست فود",
    categoryKey: "fast_food",
    sizes: ["s", "m", "l"],
    rate: 4.8,
    image: i12,
    price: 55,
    _id: 6,
    description:
      "پیتزا یک غذای فست فود محبوب است که از ترکیب مواد مختلف از جمله آرد، گوشت، سس گوجه‌فرنگی و پنیر تهیه می‌شود. با بیشترین تنوع در انواع طعم و تزئینات، پیتزا یک گزینه خوشمزه و سریع برای وعده‌های ناگهانی است.",
    time: 15,
  },
  {
    name: "سیب زمینی",
    category: "فست فود",
    categoryKey: "fast_food",
    sizes: ["s", "m", "l"],
    rate: 4.8,
    image: i10,
    price: 55,
    _id: 31,
    description:
      "سیب‌زمینی سرخ شده یک غذای فست فود لذیذ است که از سیب‌زمینی‌ها تهیه می‌شود. با طعم خاص و خوشمزه، سیب‌زمینی سرخ شده یک گزینه محبوب و پرطرفدار برای همه افراد است.",
    time: 12,
  },
  {
    name: "برگر",
    category: "فست فود",
    categoryKey: "fast_food",
    sizes: ["s", "m"],
    rate: 4.6,
    image: i2,
    price: 48,
    _id: 7,
    description:
      "برگر یک غذای فست فود محبوب است که از گوشت گاو تهیه می‌شود. با سس‌ها و تزئینات مختلف، برگر یک گزینه لذت‌بخش برای دوستداران گوشت است.",
    time: 10,
  },
  {
    name: "ساندویچ مرغ",
    category: "فست فود",
    categoryKey: "fast_food",
    sizes: ["s"],
    rate: 4.3,
    image: i13,
    price: 42,
    _id: 8,
    description:
      "ساندویچ مرغ یک غذای فست فود است که از مرغ تازه و سس‌های مختلف تهیه می‌شود. با طعم لذیذ و مواد تازه، این ساندویچ یک گزینه سبک و خوشمزه برای وعده‌های میانی روز است.",
    time: 8,
  },
  {
    name: "چای سیاه",
    category: "چای و دمنوش",
    categoryKey: "tea",
    sizes: ["s", "m", "l"],
    rate: 4.7,
    image: i14,
    price: 15,
    _id: 9,
    description:
      "چای سیاه یک نوشیدنی گرم با طعم قهوه‌ای است که از برگ‌های چای تازه تهیه می‌شود. با طعم ملس و مطبوع، چای سیاه یک گزینه محبوب برای لحظات استراحت و گفتگو است.",
    time: 3,
  },
  {
    name: "چای سبز",
    category: "چای و دمنوش",
    categoryKey: "tea",
    sizes: ["s", "m"],
    rate: 4.4,
    image: i15,
    price: 17,
    _id: 10,
    description:
      "چای سبز یک نوشیدنی گرم با طعم خنک و خاص است که از برگ‌های چای سبز تازه تهیه می‌شود. با خواص مفید و طعم ملایم، چای سبز یک گزینه سالم و لذیذ است.",
    time: 3,
  },
  {
    name: "دمنوش گل آبی",
    category: "چای و دمنوش",
    categoryKey: "tea",
    sizes: ["s"],
    rate: 4,
    image: i16,
    price: 20,
    _id: 11,
    description:
      "دمنوش گل آبی یک نوشیدنی گرم با طعم گل آبی است که از گل‌های آبی تازه تهیه می‌شود. با طعم خوشایند و خواص آرام‌بخش، دمنوش گل آبی یک گزینه مناسب برای لحظات استراحت است.",
    time: 4,
  },
  {
    name: "کیک شکلاتی",
    category: "کیک و دسر",
    categoryKey: "cake_dessert",
    sizes: ["s", "m", "l"],
    rate: 4.9,
    image: i17,
    price: 35,
    _id: 12,
    description:
      "کیک شکلاتی یک دسر خوشمزه و شیرین است که از شکلات با کیفیت و مواد تازه تهیه می‌شود. با لایه‌های خردلی و شکلاتی، این کیک یک گزینه عالی برای دسرهای خاص و جشن‌ها است.",
    time: 35,
  },
  {
    name: "کیک وانیلی",
    category: "کیک و دسر",
    categoryKey: "cake_dessert",
    sizes: ["s", "m"],
    rate: 4.6,
    image: i18,
    price: 32,
    _id: 13,
    description:
      "کیک وانیلی یک دسر خامه‌ای و خوشمزه است که از وانیل و مواد تازه تهیه می‌شود. با طعم ملس و لایه‌های خامه، این کیک یک گزینه خوشمزه برای لحظات شیرین است.",
    time: 32,
  },
  {
    name: "پاستا",
    category: "غذای اصلی",
    categoryKey: "main_dishes",
    sizes: ["s"],
    rate: 4.2,
    image: i26,
    price: 28,
    _id: 16,
    description:
      "پاستا یک غذای اصلی ایتالیایی است که از آرد سفید و آب تهیه می‌شود. با انواع سس‌ها و تزئینات مختلف، پاستا یک گزینه خوشمزه و سرشار از انرژی برای وعده نهار یا شام است.",
    time: 28,
  },
  {
    name: "کیک مربا",
    category: "کیک و دسر",
    categoryKey: "cake_dessert",
    sizes: ["s", "m"],
    rate: 4.5,
    image: i19,
    price: 32,
    _id: 17,
    description:
      "کیک مربا یک دسر شیرین و خوشمزه است که با استفاده از مربای خاص تهیه می‌شود. لایه‌های مختلف مربا درون کیک این دسر را بسیار لذت‌بخش و منحصر به فرد می‌کنند.",
    time: 30,
  },
  {
    name: "شربت آلبالو",
    category: "نوشیدنی سرد",
    categoryKey: "cold_drink",
    sizes: ["s", "m"],
    rate: 3.7,
    image: i6,
    price: 20,
    _id: 21,
    description:
      "شربت آلبالو یک نوشیدنی سرد و خنک‌کننده است که از آبلیمو و آلبالو تازه تهیه می‌شود. طعم مطبوع و شیرین آلبالو در هر لیوان از این نوشیدنی باعث شادابی و خوشمزگی می‌شود.",
    time: 25,
  },
  {
    name: "سالاد سزار",
    category: "سالاد",
    categoryKey: "salad",
    sizes: ["s", "m", "l"],
    rate: 4.4,
    image: i23,
    price: 30,
    _id: 22,
    description:
      "سالاد سزار یک سالاد خوشمزه و کامل است که از سبزیجات تازه، پنیر پارمزان، سس سزار و کراتون خرد شده تهیه می‌شود. طعم ترکیبی از تازگی و لذت در هر قاشقی از این سالاد به شما خواهد آمد.",
    time: 15,
  },
  {
    name: "کیک میوه‌ای",
    category: "کیک و دسر",
    categoryKey: "cake_dessert",
    sizes: ["s", "m"],
    rate: 4.6,
    image: i20,
    price: 35,
    _id: 23,
    description:
      "کیک میوه‌ای یک دسر خوشمزه و سرشار از میوه‌های تازه است. انواع میوه‌های مختلف در لایه‌ها و روی کیک، این دسر را به یک گزینه شیرین و مفید تبدیل می‌کنند.",
    time: 40,
  },
  {
    name: "آب پرتقال",
    category: "نوشیدنی سرد",
    categoryKey: "cold_drink",
    sizes: ["s"],
    rate: 4.3,
    image: i1,
    price: 22,
    _id: 24,
    description:
      "آب پرتقال یک نوشیدنی سرد و خوشمزه است که از ترکیب آب پرتقال تازه گرفته شده و یخ تهیه می‌شود. این نوشیدنی مناسب برای روزهای گرم و تازه‌کننده است.",
    time: 18,
  },
  {
    name: "کیک کارامل",
    category: "کیک و دسر",
    categoryKey: "cake_dessert",
    sizes: ["s", "m", "l"],
    rate: 4.9,
    image: i21,
    price: 38,
    _id: 26,
    description:
      "کیک کارامل یک دسر لذیذ و شیرین است که با استفاده از شکر کاراملی و مواد تازه تهیه می‌شود. لایه‌های مختلف این کیک به شما تجربه‌ای متفاوت از شیرینی خواهد داد.",
    time: 25,
  },
  {
    name: "قارچ سوخاری",
    category: "پیش‌ غذا",
    categoryKey: "appetizer",
    sizes: ["s", "m"],
    rate: 3.5,
    image: i25,
    price: 25,
    _id: 28,
    description:
      "قارچ سوخاری یک پیش‌غذای خوشمزه و سریع الهضم است که از قارچ‌های تازه و طعم‌دهنده تهیه می‌شود. پوشیدنی و خوشمزه، این پیش‌غذا را به یک انتخاب عالی برای شروع وعده‌های غذایی تبدیل می‌کند.",
    time: 20,
  },
  {
    name: "کیک تیرامیسو",
    category: "کیک و دسر",
    categoryKey: "cake_dessert",
    sizes: ["s"],
    rate: 4.8,
    image: i22,
    price: 40,
    _id: 29,
    description:
      "کیک تیرامیسو یک دسر خاص و اصیل ایتالیایی است که با استفاده از لایه‌های مختلف بیسکوئیت و ماسکارپونه تهیه می‌شود. طعم ملایم و خاص آن هر علاقه‌مندی را جذب خواهد کرد.",
    time: 30,
  },
  {
    name: "سالاد مرغ",
    category: "سالاد",
    categoryKey: "salad",
    sizes: ["s", "m"],
    rate: 4.6,
    image: i24,
    price: 35,
    _id: 30,
    description:
      "سالاد مرغ یک سالاد خوشمزه و کامل است که از مرغ پخته شده، سبزیجات تازه، سس مایونز و ادویه‌های گلچین شده تهیه می‌شود. این سالاد به عنوان یک وعده ناهار یا شام سبک بسیار مناسب است.",
    time: 20,
  },
];

export default products;
