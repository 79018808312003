// Modules
import { useContext, useEffect, useRef, useState } from "react";
import { useParams, Link } from "react-router-dom";
// Context
import { CardContext } from "../../../Context/CardContext";
// Styles
import Styles from "./Styles/SinglePage.module.css";
// Data
import data from "../../../Utils/products";
// Icons
import backIcon from "../../../Assets/Icons/back.png";
import starIcon from "../../../Assets/Icons/star.png";
import cartIcon from "../../../Assets/Icons/basket.png";

const SinglePage = () => {
  const { id } = useParams();
  const [singleData, setSingleData] = useState({});
  const [selectedSize, setSelectedSize] = useState("s");
  const { itemsCount, addItem, deleteItem } = useContext(CardContext);
  const countRef = useRef(null);

  const changeSelectedSize = (size) => {
    setSelectedSize(size);
  };

  const handleAddToCard = (item) => {
    addItem(item);
    countRef.current.classList.add(Styles.animate);
    setTimeout(() => {
      countRef.current.classList.remove(Styles.animate);
    }, 1000);
  };

  useEffect(() => {
    const index = data.findIndex((i) => i._id === +id);
    setSingleData(data[index]);
  }, [id]);

  return (
    <div className={Styles.container}>
      <div className={Styles.imageContainer}>
        <img src={singleData.image} alt="" />
        <div className={Styles.detailsContainer}>
          <div className={Styles.top}>
            <h2 className={Styles.name}>{singleData.name}</h2>
            <h2 className={Styles.price}>{singleData.price} هزار تومان</h2>
          </div>
          <div className={Styles.bottom}>
            <div className={Styles.starsContainer}>
              {singleData.rate}
              <div
                className={Styles.star}
                style={{
                  width:
                    singleData.rate * 15 + singleData.rate * 2 + 0.5 + "px",
                }}
              >
                <img src={starIcon} alt="" />
                <img src={starIcon} alt="" />
                <img src={starIcon} alt="" />
                <img src={starIcon} alt="" />
                <img src={starIcon} alt="" />
              </div>
            </div>
            <h5 className={Styles.time}>
              زمان تحویل : {singleData.time} دقیقه
            </h5>
          </div>
        </div>
        <Link to="/" className={Styles.backImage}>
          <img src={backIcon} alt="" />
        </Link>
      </div>
      <div className={Styles.description}>
        <h3>توضیحات</h3>
        <p>{singleData.description}</p>
      </div>
      <div className={Styles.sizes}>
        <h3>سایز</h3>
        <div className={Styles.sizesContainer}>
          <div
            onClick={() => changeSelectedSize("s")}
            className={`${Styles.size} ${
              selectedSize === "s" ? Styles.activeSize : {}
            }`}
          >
            کوچک
          </div>
          <div
            onClick={() => changeSelectedSize("m")}
            className={`${Styles.size} ${
              selectedSize === "m" ? Styles.activeSize : {}
            }`}
          >
            متوسط
          </div>
          <div
            onClick={() => changeSelectedSize("l")}
            className={`${Styles.size} ${
              selectedSize === "l" ? Styles.activeSize : {}
            }`}
          >
            بزرگ
          </div>
        </div>
      </div>
      <div className={Styles.cartContainer}>
        <div
          className={Styles.addToCart}
          onClick={() => handleAddToCard(singleData)}
        >
          افزودن به سبد خرید
        </div>
        <div className={Styles.cart}>
          <img src={cartIcon} alt="" />
          <h6 ref={countRef}>{itemsCount}</h6>
        </div>
      </div>
    </div>
  );
};

export default SinglePage;
